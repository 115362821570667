<template>
  <img
      class="currency-img"
      :src="currentCurrencyImg"
      :alt="currency"
  >
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'CurrencyImg',
    props: {
        currency: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'getCurrencyNameById',
        ]),
        ticker() {
            return this.currency.coin;
        },
        currentCurrencyImg() {
            return `/assets/image/coins-logo/${this.ticker}.png`;
        },
    },
};

</script>
