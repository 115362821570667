import axios from "axios";

export const createDonationOrder = async ({commit}, payload) => {
    let response;
    try {
        response = await axios.post("/public-api/pre-checkout/pay", payload);
    } catch (e) {
        return e.response;
    }
    return response;
};