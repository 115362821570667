export const mutations = {
    setFetching(state, value) {
        state.fetching = value;
    },
    setOrder(state, order) {
        /*
            title: pageInfo.title,
            alias: page.alias,
            id: page.id,
            url: page.url,
            info: pageInfo,
            currencies: pageCurrencies,
            utm: utm,
            token: token,
            traceId,
        * */

        state.precheckout = {
            id: order.id,
            url: order.url,
            info: order.info,
            currencies: order.currencies,
            token: order.token,
            utm: order.utm,
            trace: order.traceId,
            alias: order.alias,
        };

    },
    changeLangMutations: (state, lang) => {
        state.lang = lang;
    },
    setCurrencies(state, payload) {
        state.currencies = payload;
    },

};
