<template>
  <donations-container>
    <template #content>
      <div class="new-donation-modal__top-container" :style="`--bg-color:var(--${theme})`">
        <slot name="user-info"></slot>
        <slot name="details"></slot>
      </div>
    </template>
    <template #button>
      <slot name="button"></slot>
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </donations-container>
</template>

<script>
import DonationsContainer from "./donations-container";

export default {
    name: "donation-pre-checkout",
    components: {DonationsContainer},
    props: {
        theme: {
            type: String,
            default: '--dark_blue_gradient',
        },

    },
};
</script>

<style scoped lang="scss">
.new-donation-modal {
  &__top-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 611px;
    background: var(--bg-color);
    border-radius: 12px;
    margin: 15px;
    position: relative;
  }
}

@media screen and (max-width: 950px) {
  .new-donation-modal {
    &__top-container {
      margin: 9px;
      padding: 10px;
    }
  }
}
</style>