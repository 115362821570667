<template>
  <div class="new-donation-modal">
    <DonationPreCheckout class="new-donation-modal__wrap" :theme="precheckout.info.theme"
                         v-if="!isFailed && !paymentLink">
      <template #user-info>
        <div class="new-donation-modal__donation-info">
          <HeaderLanguage class="new-donation-modal__language"/>
          <div class="new-donation-modal__user-info">

            <div class="new-donation-modal__avatar">
              <img class="new-donation-modal__avatar-image" v-if="precheckout.info.avatarLink"
                   :src="precheckout.info.avatarLink"/>
              <svg class="new-donation-modal__avatar-image" v-else width="106" height="106" viewBox="0 0 106 106"
                   fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="53" cy="53" r="50.5" fill="#E7F0FF" stroke="white" stroke-width="5"/>
                <path
                    d="M52.9998 51.1667C48.9497 51.1667 45.6665 47.8834 45.6665 43.8333C45.6665 39.7832 48.9497 36.5 52.9998 36.5C57.0499 36.5 60.3332 39.7832 60.3332 43.8333C60.3332 47.8834 57.0499 51.1667 52.9998 51.1667Z"
                    stroke="#0059F7" stroke-width="2" stroke-linecap="round"/>
                <path
                    d="M38.3333 69.5V67.6667C38.3333 61.5915 43.2581 56.6667 49.3333 56.6667H56.6666C62.7417 56.6667 67.6666 61.5915 67.6666 67.6667V69.5"
                    stroke="#0059F7" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="new-donation-modal__text-container">
              <p
                  class="new-donation-modal__user-info-text new-donation-modal__user-info-text_url"
              >{{ location }}</p>
            </div>
          </div>

          <div class="new-donation-modal__donation-text-container">
            <div class="new-donation-modal__input-container">
              <p class="new-donation-modal__description-title">{{ decodeURIComponent(precheckout.info.title) }}</p>
            </div>
            <div class="new-donation-modal__input-container new-donation-modal__input-container_description">
              <p class="new-donation-modal__description-subtitle">{{
                  decodeURIComponent(precheckout.info.description)
                }}</p>
            </div>
          </div>
          <div class="new-donation-modal__currency-input-container-wrap">
            <p class="new-donation-modal__input-label">{{ $t('preCheckout.inputs.labels.currency') }}</p>
            <div class="new-donation-modal__currency-input-container">

              <div class="new-donation-modal__currency-input-selected" v-if="currencySelected">
                <div class="new-donation-modal__currency-input-selected-container">
                  <CurrencyImg class="new-donation-modal__currency-input-selected-image"
                               :currency="currencySelected"/>
                  <p class="new-donation-modal__currency-input-selected-currency">{{ currencySelected.coinName }}</p>
                </div>
                <p class="new-donation-modal__currency-input-selected-currency  new-donation-modal__currency-input-selected-currency_network">
                  {{ currencySelected.networkName }}</p>
              </div>
              <AtSelect v-model="currency" :placeholder="$t('preCheckout.inputs.placeholders.currency')"
                        class="new-donation-modal__input-select" v-if="precheckout.currencies">
                <AtOption :value="`${currenciesItem.coinId}_${currenciesItem.networkId}`"
                          v-for="currenciesItem in precheckout.currencies"
                          :key="currenciesItem.coinId + currenciesItem.networkId">
                  <div class="new-donation-modal__currency-item" v-if="currenciesItem.coinId">
                    <div class="new-donation-modal__currency-info">
                      <CurrencyImg class="new-donation-modal__currency-input-selected-image"
                                   :currency="currenciesItem"/>
                      <div class="new-donation-modal__currency-text-container">
                        <p class="new-donation-modal__currency-text-title">{{ currenciesItem.coin }}</p>
                        <p class="new-donation-modal__currency-text-subtitle">{{ currenciesItem.coinName }}</p>
                      </div>
                    </div>
                    <p class="new-donation-modal__currency-text">{{ currenciesItem.networkName }}</p>
                  </div>
                </AtOption>
              </AtSelect>
            </div>
          </div>
          <div class="new-donation-modal__currency-amount-container">
            <p class="new-donation-modal__input-label">{{ $t('preCheckout.inputs.labels.amount') }}</p>
            <AtInput :placeholder="$t('preCheckout.inputs.placeholders.amount')" type="number" :min="0"
                     v-model="amount"/>
            <p class="new-donation-modal__currency-amount-usd" v-if="amount && currencySelected">
              {{ `${usdConversion} USD` }}
            </p>
          </div>
        </div>

        <div class="new-donation-modal__currency-container">
          <div class="new-donation-modal__currency-input-email">
            <p class="new-donation-modal__input-label new-donation-modal__input-label_dark">{{
                $t('preCheckout.inputs.labels.alias')
              }}</p>
            <AtInput :placeholder="$t('preCheckout.inputs.placeholders.alias')" :maxlength="40" v-model="nickname"/>
          </div>
          <div class="new-donation-modal__currency-input-comment">
            <p class="new-donation-modal__input-label new-donation-modal__input-label_dark">
              {{ $t('preCheckout.inputs.labels.commentary') }}</p>
            <AtTextarea :placeholder="$t('preCheckout.inputs.placeholders.commentary')" v-model="message"
                        :resize="'none'" autosize :maxlength="200"/>
          </div>
        </div>

      </template>
      <template #details>

      </template>
      <template #button>
        <AtButton :disabled="isButtonDisabled" @click="sendPayment" class="new-donation-modal__button">{{
            $t('preCheckout.button.payment')
          }}
        </AtButton>
      </template>
      <template #footer>
        <footer class="new-donation-modal__footer">
          <p class="new-donation-modal__footer-text">{{ $t('preCheckout.footer.product_2022', {
            productName: productName
          }) }}</p>

        </footer>
      </template>
    </DonationPreCheckout>
    <template>
      <div class="new-donation-modal__error">
        <div class="fail success" v-if="paymentLink !== null || paymentLink">
          <div class="success__text-container">

            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z"
                  fill="#4CAF50"/>
              <path d="M34.5996 14.6L20.9996 28.2L15.3996 22.6L12.5996 25.4L20.9996 33.8L37.3996 17.4L34.5996 14.6Z"
                    fill="#CCFF90"/>
            </svg>

            <p class="success__title">{{ $t('preCheckout.new-tab') }}</p>
            <p class="success__title">{{ $t('preCheckout.nothing-happening') }} <a
                :href="paymentLink">
              {{ $t('preCheckout.here') }}
            </a></p>
            <button class="success__button-redirect" @click.prevent="goBack">
              {{ $t('create-donation.button.go_back') }}
            </button>
          </div>

        </div>
      </div>
    </template>
    <template>
      <div class="new-donation-modal__error">
        <div class="fail success" v-if="isFailed !== null || isFailed">
          <div class="success__text-container">
            <svg id="Layer_1"
                 width="48" height="48"
                 x="0px" y="0px"
                 viewBox="0 0 64 64">
              <g>
                <g id="XMLID_35_">
                  <g>
                    <g>
                      <path fill="#F94646" d="M32,0c17.7,0,32,14.3,32,32S49.7,64,32,64S0,49.7,0,32S14.3,0,32,0z"/>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <rect x="16.4" y="30" transform="matrix(0.7071 0.7071 -0.7071 0.7071 32 -13.2548)" fill="#FFFFFF"
                            width="31.1"
                            height="4"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="30" y="16.4" transform="matrix(0.7071 0.7071 -0.7071 0.7071 32 -13.2548)" fill="#FFFFFF"
                            width="4"
                            height="31.1"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <p class="success__title">{{ $t($options.codes[code]) }}</p>
          </div>
          <button class="success__button-redirect" @click.prevent="goBack">
            {{ $t('create-donation.button.ok') }}
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import {mapActions, mapState} from "vuex";

import HeaderLanguage from "../precheckout/components/donations/HeaderLanguage";
import DonationPreCheckout from "./components/donations/donation-pre-checkout";
import DonationsContainer from "./components/donations/donations-container";
import CurrencyImg from "./components/CurrencyImg";

export default {
    name: "MainPage",
    codes: {
        1000: 'preCheckout.errors.unknown_error',
        1001: 'preCheckout.errors.fail_to_create_order',
        1002: 'preCheckout.errors.external_service_error',
        2000: 'preCheckout.errors.token_is_required',
        2001: 'preCheckout.errors.currency_and_network_is_required',
        2002: 'preCheckout.errors.invalid_token',
        2003: 'preCheckout.errors.page_not_found',
        2004: 'preCheckout.errors.page_not_accepted_deposits_in_currency',
        2005: 'preCheckout.errors.currency_not_allowed_for_deposits',
    },
    data() {
        return {
            currency: '',
            amount: 0,
            nickname: null,
            message: null,
            isFailed: null,
            code: null,
            paymentLink: null,
            productName: import.meta.env.VITE_PRODUCT_NAME
        };
    },
    components: {
        CurrencyImg,
        DonationPreCheckout,
        DonationsContainer,
        HeaderLanguage,
    },
    computed: {
        ...mapState(['precheckout']),
        theme() {
            return this.precheckout;
        },
        location() {
            return window.location.host + window.location.pathname;
        },
        currencySelected() {
            if (this.currency) {
                const [coinId, networkId] = this.currency.split('_');
                return this.precheckout.currencies.find((i) => i.coinId === coinId && i.networkId === networkId);
            }
            return null;
        },
        usdConversion() {
            if (this.currencySelected && this.amount) {
                const amount = new BigNumber(this.amount);
                const usdPrice = new BigNumber(this.currencySelected.priceUSD);
                const result = amount.multipliedBy(usdPrice);
                return result;
            }
            return 0;
        },
        isButtonDisabled() {
            const {currency, amount} = this;
            return !currency || !(amount > 0);
        },
    },
    methods: {
        ...mapActions(['createDonationOrder']),
        goBack() {
            this.isFailed = null;
            this.code = null;
            this.currency = '';
            this.amount = 0;
            this.nickname = null;
            this.message = null;
            this.paymentLink = null;
        },
        async sendPayment() {
            let result;
            try {
                result = await this.createDonationOrder({
                    amount: this.amount,
                    currency: this.currency,
                    nickname: this.nickname,
                    message: this.message,
                    token: this.precheckout.token,
                });
                const {
                    data: {
                        paymentLink,
                        code,
                    },
                } = result;
                if (code) {
                    this.code = code;
                    this.isFailed = true;
                    return;
                }
                this.paymentLink = paymentLink;
                window.open(paymentLink);
            } catch (e) {
                this.code = 1000;
                this.isFailed = true;
            }


        },
    },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

:root {
  //Gradients & precheckout colors
  --dark_blue_gradient: linear-gradient(294.6deg, #0059F7 -60.71%, #6A62CB 92.86%);
  --pink_gradient: linear-gradient(294.6deg, #F700B2 -60.71%, #BC62CB 92.86%);
  --purple_gradient: linear-gradient(294.6deg, #C600F7 -60.71%, #7762CB 92.86%);
  --blue_gradient: linear-gradient(294.6deg, #5E00F7 -60.71%, #2EB7E2 92.86%);
  --green_gradient: linear-gradient(294.6deg, #00ADF7 -60.71%, #32D691 92.86%);
  --yellow_gradient: linear-gradient(294.6deg, #FA6C06 -60.71%, #FEDE37 92.86%);
  --orange_gradient: linear-gradient(294.6deg, #FAC406 -60.71%, #FE4F37 92.86%);
  --black: linear-gradient(294.6deg, #373737 -60.71%, #242424 92.86%, #242424 92.86%);
  --dark_blue: linear-gradient(116.14deg, #1F1597 11.25%, #2C1A72 127.57%, #897FF7 127.57%);
  --pink: #F700B2;
  --green: linear-gradient(294.6deg, #4FA23B -60.71%, #52C965 92.86%);
  --purple: #B132ED;
  --blue: #32B5ED;
  --yellow: #F4BD50;
  --orange: #EF6336;

  //Colors
  --white-100: #FFFFFF;
  --grey-100: #F9F9F9;
  --grey-200: #FCFCFC;
  --grey-300: #8F949A;
  --black-200: #2C405A;
  --blue-300: #0059F7;
  --blue-400: #0028F7;

  //Gradient


  //padding
  --padding-left-container: 60px;
  --padding-right-container: 60px;
  --padding-top-container: 20px;

  //box-shadow
  --shadow-container: 0px 8px 50px rgb(10 17 105 / 8%);

  //font
  --font-family: 'Roboto';
  --font-size-h1: 36px;
  --font-size-h2: 24px;
  --font-size-h3: 20px;
  --font-size-text-main: 16px;
  --font-size-text-secondary: 14px;
  --font-size-text-caption: 12px;
  --line-height: 150%;


  //border
  --border-table: 1px solid #F2F2F2;

  //margin
  --margin-container-top: 32px;
  --margin-container-bottom: 32px;
  --margin-h1-bottom: 16px;
}

@media screen and (max-width: 1300px) {
  :root {
    --padding-left-container: 20px;
    --padding-right-container: 20px;
  }
}

@media screen and (max-width: 1180px) {
  :root {
    --padding-left-container: 20px;
    --padding-right-container: 20px;
    --padding-top-container: 25px;
    --font-size-h1: 20px;
    --font-size-h2: 18px;
    --font-size-h3: 16px;
    --font-size-text-main: 14px;
    --font-size-text-secondary: 12px;
    --margin-container-top: 20px;
    --margin-container-bottom: 20px;
  }
}

@media screen and (max-width: 375px) {
  :root {
    --padding-left-container: 0;
    --padding-right-container: 0;
  }

}


</style>

<style lang="scss" scoped>

.success {
  position: absolute;
  inset: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-height: 366px;
  max-width: 764px;
  background: var(--white-100);
  border-radius: 12px;
  align-items: center;
  justify-content: center;

  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  &__title {
    font-family: var(--font-family);
    font-size: var(--font-size-h2);
    line-height: var(--line-height);
  }

  &__button-redirect {
    min-height: 60px;
    border-radius: 12px;
    background: var(--blue-300);
    max-width: 300px;
    color: var(--white-100);
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border: none;

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }
  }
}

.language {
  justify-self: flex-end;
  max-width: 50px;
  position: absolute;
  top: 0;
  right: 0;
}

.new-donation-modal {
  display: grid;

  &__input-container {
    &_description {
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    &-text {
      font-family: var(--font-family);
      font-weight: 300;
      font-size: var(--font-size-text-main);
      color: var(--black-200);
    }
  }

  &__language {
    max-width: 50px;
    align-self: flex-end;
    margin-right: 10px;

    ::v-deep .at-select__selection {
      background: transparent;
      color: var(--white-100) !important;
    }

    ::v-deep .at-select__arrow {
      color: var(--white-100) !important;
    }
  }

  &__wrap {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  &__avatar {
    height: 106px;
    width: 106px;
    border-radius: 50%;

    &-image {
      height: 106px;
      width: 106px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  padding-right: var(--padding-right-container);
  padding-left: var(--padding-left-container);
  padding-top: var(--padding-top-container);


  &__all {
    background: #0059F7;
    margin-bottom: 16px;
    border-radius: 6px;
    border: none;
    width: 100%;
    max-width: 125px;
    min-height: 40px;
    transition: all 0.3s ease-in;

    &:hover {
      background: var(--blue-400);
    }

    ::v-deep .at-btn {
      max-width: 125px;
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
    }

    ::v-deep .at-btn__icon {
      font-size: var(--font-size-text-main);
    }

  }


  &__description {
    &-title {
      font-family: var(--font-family);
      font-size: var(--font-size-h2);
      line-height: var(--line-height);
      font-weight: 500;
      color: var(--white-100);
    }

    &-subtitle {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
      display: -webkit-box;
      -webkit-line-clamp: 13;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
  }

  &__button {
    min-height: 60px;
    border-radius: 12px;
    background: var(--blue-300);
    max-width: 673px;
    color: var(--white-100);

    align-self: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: auto;
    margin-bottom: 0;

    ::v-deep .at-btn__text {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
    }

    &:hover {
      background: var(--blue-400);
    }
  }


  &__text {
    &-main {
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      color: var(--black-200);
    }
  }

  &__option {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  /* Check */

  &__check {
  }

  &__check-input {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &_list {
      width: calc(100% + 80px);
      height: 100%;
      top: 0;
      left: -40px;
      border-bottom: var(--border-table);
      z-index: 3;
      cursor: pointer;
    }
  }


  &__check-box {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: 2;
  }

  &__check-focus {
    fill: none;
  }

  &__check-square {
    fill: #ffffff;
  }


  /* Checked */

  &__check-input:checked + * &__check-square {
    fill: #2939EC;
    stroke: transparent;
  }

  &__check-input:checked ~ &__check-input-bg {
    background: #E7F0FF;
    width: calc(100% + 80px);
    height: 100%;
    top: 0;
    left: -40px;
    z-index: 1;
    position: absolute;
  }

  /* Focused */

  &__check-input:focus + * &__check-focus {
    fill: #7ed321;
  }

  /* Disabled */

  &__check-input:disabled + * &__check-square {
    stroke: #9b9b9b;
  }

  &__check-input:checked:disabled + * &__check-square {
    fill: #9b9b9b;
  }

  &__currency {
    &-img {
      width: 34px;
      height: 34px;
      z-index: 2;
    }

    &-input-email {
      ::v-deep .at-input__original {
        padding-left: 32px;
        border-radius: 12px;
        font-family: var(--font-family);
        font-size: var(--font-size-h3);
        line-height: var(--line-height);
        color: var(--black-200);
        height: 45px;
        border: 1px solid #F2F2F2;
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
      }
    }

    &-input-comment {
      ::v-deep .at-textarea__original {
        padding-left: 32px;
        border-radius: 12px;
        padding-top: 10px;
        font-family: var(--font-family);
        font-size: var(--font-size-h3);
        line-height: var(--line-height);
        color: var(--black-200);
        border: 1px solid #F2F2F2;
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
        min-height: 115px !important;
      }
    }

    &-amount {
      &-container {
        width: 100%;
        max-width: 672px;
        display: flex;
        flex-direction: column;

        ::v-deep .at-input__original {
          padding-left: 32px;
          border-radius: 12px;
          font-family: var(--font-family);
          font-size: var(--font-size-h3);
          line-height: var(--line-height);
          color: var(--black-200);
          height: 64px;
        }
      }

      &-usd {
        padding-left: 32px;
        font-family: var(--font-family);
        font-size: var(--font-size-text-main);
        line-height: var(--line-height);
        color: var(--white-100);
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-of-type .new-donation-modal__check-input {
        border-top: var(--border-table);
      }
    }

    &-input-selected {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%;
      width: 100%;
      background: white;
      border-radius: 12px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding-left: 32px;

      ::v-deep .at-select__selected {
        color: transparent;
        display: none;
      }

      &-image {
        height: 34px;
      }

      &-currency {
        font-family: var(--font-family);
        font-size: var(--font-size-h3);
        font-weight: 500;
        line-height: var(--line-height);
        color: var(--black-200);

        &_network {
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          max-width: 190px;
          color: #8F949A
        }
      }

      &-container {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    &-input-container {
      width: 100%;
      max-width: 672px;
      position: relative;
      background: var(--white-100);
      border-radius: 12px;
      margin-bottom: 20px;

      &-wrap {
        width: 100%;
        max-width: 672px;
        display: flex;
        flex-direction: column;
        margin-top: 45px;
      }
    }

    &-container {
      position: absolute;
      bottom: -260px;
      background: var(--white-100);
      padding: 20px 40px;
      height: 100%;
      max-height: 290px;
      width: 100%;
      max-width: 778px;
      border-radius: 12px;
      overflow: auto;
      box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 65px;
      }


      &-title {
        display: flex;
        align-items: center;
        gap: 15px;
        z-index: 2;
      }
    }

    &-item {
      min-height: 60px;
      display: grid;
      align-content: center;
      justify-content: space-between;
      position: relative;
      grid-template-columns: 1fr 1fr;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-title {
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      color: var(--black-200);
    }

    &-select-all {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-text-container {
      display: flex;
      flex-direction: column;
    }

    &-text {
      z-index: 2;
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      color: var(--grey-300);
    }

    &-text-title {
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
      line-height: var(--line-height);
      font-weight: 500;
      z-index: 2;
    }

    &-text-subtitle {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--grey-300);
    }

  }

  &__donation-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__label {
    font-family: var(--font-family);
    font-size: var(--font-size-text-secondary);
    line-height: var(--line-height);
    color: var(--white-100);
  }

  &__user-info {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
    width: 100%;
    max-width: 672px;

    &-text {
      font-family: var(--font-family);
      font-size: var(--font-size-h2);
      line-height: var(--line-height);
      color: var(--white-100);
      word-break: break-word;

      &_url {
        font-size: var(--font-size-h3);

      }
    }


  }

  &__input {

    &-label {
      font-family: var(--font-family);
      font-size: var(--font-size-text-main);
      line-height: var(--line-height);
      color: var(--white-100);
      font-weight: 300;
      margin-bottom: 10px;

      &_dark {
        color: var(--black-200);
        font-weight: 400;
      }
    }

    ::v-deep .at-input__original {
      min-height: 55px;
      font-family: var(--font-family);
      font-size: var(--font-size-text-secondary);
      line-height: var(--line-height);
      color: var(--black-200);
      border-radius: 12px;
      padding: 20px;

      &::placeholder {
        font-family: var(--font-family);
        font-size: var(--font-size-text-main);
        line-height: var(--line-height);
      }
    }


  }

  &__input-select {
    background: transparent;
    z-index: 2;

    ::v-deep .at-select__selected {
      display: none;
    }

    ::v-deep .icon {
      font-size: 24px;
    }

    ::v-deep .at-select__arrow {
      top: calc(50% - 6px);
      font-size: 24px;
    }

    ::v-deep .at-select__selection {
      min-height: 64px;
      border-radius: 12px;
      padding-left: 32px;
      background: transparent;
      padding-top: 19px;
    }

    ::v-deep .at-select__placeholder {
      font-family: var(--font-family);
      line-height: var(--line-height);
      font-size: var(--font-size-h3);
      color: #C9C9C9;
    }
  }


  &__textarea {
    ::v-deep .at-textarea__original {
      min-height: 150px;
      font-family: var(--font-family);
      font-size: var(--font-size-text-secondary);
      line-height: var(--line-height);
      color: var(--black-200);
      border-radius: 12px;
      padding: 20px;

      &::placeholder {
        font-family: var(--font-family);
        font-size: var(--font-size-text-main);
        line-height: var(--line-height);
      }

    }
  }

  &__textarea-container {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--white-100);
    position: relative;
  }

  &__textarea-prepend {
    padding: 0 0 10px;
    color: var(--white-100);
    font-family: var(--font-family);
    font-size: var(--font-size-h3);
  }

  &__donation-text-container {
    max-width: 672px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }


  &__user-info-input {


    ::v-deep .at-input__original {
      background: transparent;
      border: none;
      color: var(--white-100);
      caret-color: var(--white-100);
      padding: 0 0 5px;
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
    }

    ::v-deep .at-input-group__prepend {
      background: transparent;
      border: none;
      padding: 0 0 5px;
      color: var(--white-100);
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
    }

    &_alias {
      border-bottom: 1px solid var(--white-100);

      ::v-deep .at-input__original {
        font-size: var(--font-size-h2);
      }
    }

    ::v-deep .at-textarea__original {
      background: transparent;
      border: none;
      color: var(--white-100);
      caret-color: var(--white-100);
      padding: 0 0 5px;
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
    }
  }


  &__url-input {
    border-bottom: 1px solid var(--white-100);

    ::v-deep .at-input__original {
      background: transparent;
      border: none;
      padding: 0 0 10px;
      color: var(--white-100);
      caret-color: var(--white-100);
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
    }

    ::v-deep .at-input-group__prepend {
      background: transparent;
      border: none;
      padding: 0 0 10px;
      color: var(--white-100);
      font-family: var(--font-family);
      font-size: var(--font-size-h3);
    }
  }

  &__container {
    position: relative;
    height: 100%;
    background: #FFFFFF;
    width: 100%;
    max-width: 1125px;
    -webkit-clip-path: url(#my-clip-path);
    clip-path: url(#my-clip-path);
  }

  &__top-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 611px;
    background: var(--bg-color);
    border-radius: 12px;
    margin: 15px;
  }

  &__color {
    max-width: 165px;

    &-placeholder {
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      &-text {
        color: var(--white-100);
        font-size: var(--font-size-text-secondary);
        line-height: var(--line-height);
        font-family: var(--font-family);
      }
    }

    &-container {
      position: relative;
      width: 100%;
      max-width: 165px;
    }


    ::v-deep .at-select__selection {
      background: transparent;
      border: 1px dashed var(--white-100);
      min-height: 96px;
    }

    ::v-deep .at-select__dropdown {
      width: calc(100% + 135px);
      top: unset !important;
      left: unset !important;
    }

    ::v-deep .at-select__arrow {
      display: none;
    }

    ::v-deep .at-select__dropdown .at-select__list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      padding: 10px;

      border-radius: 12px;
    }

    ::v-deep .at-select__option {
      width: 48px;
      height: 48px;
      background: var(--bg-color-option);
      color: transparent;
      border-radius: 12px;
    }

    ::v-deep .at-select__selected {
      color: transparent;
      display: none;
    }
  }


}

@media screen and (max-width: 950px) {
  .new-donation-modal {
    &__input {
      &-select {
        ::v-deep .at-select__selection {
          padding-left: 16px;
        }
      }
    }

    &__user-info {
      margin-top: 50px;
    }

    &__avatar {
      position: relative;
      height: 57px;
      width: 57px;

      &-hover {
        width: 57px;
        height: 57px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        gap: 5px;
      }


      &-text {
        display: none;
      }

      &-image {
        height: 57px;
        width: 57px;
      }

      &_placeholder {
        height: 57px;
        width: 57px;
      }
    }

    &__currency-container {
      max-width: 88%;
      max-height: 260px;
      padding: 10px;
    }

    &__currency {

      &-input {
        &-email {
          ::v-deep .at-input__original {
            padding-left: 16px;
          }
        }

        &-comment {
          ::v-deep .at-textarea__original {
            padding-left: 16px;
          }
        }
      }

      &-amount {
        &-container {
          ::v-deep .at-input__original {
            padding-left: 16px;
          }
        }

      }

      &-container {
        gap: 15px;
      }

      &-input-comment {
      }
    }

    &__currency-text {
      font-size: var(--font-size-text-main);
      text-align: end;
    }

    &__button {
      max-width: calc(100% - 40px);
    }
  }


}

@media screen and (max-width: 475px) {
  .new-donation-modal {
    &__input {
      &-select {
        ::v-deep .icon {
          font-size: 18px;
          line-height: 18px;
          top: calc(50% - 4px);
        }
      }
    }

    &__currency {


      &-input {

        &-selected {
          padding-left: 10px;

          &-image {
            height: 26px;
          }

          &-container {
            gap: 8px;


          }

          &-currency {
            font-size: var(--font-size-text-main);

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &_network {
              max-width: 120px;
              font-size: var(--font-size-text-caption);
            }

          }
        }
      }

      &__user-info {
        display: grid;
        grid-template-areas: 'avatar text'
                           'color color';
        max-width: 100%;
        justify-content: center;
      }

      &__color-container {
        grid-area: color;
        max-width: 100%;
      }

      &__color {
        max-width: 100%;

        ::v-deep .at-select__dropdown {
          width: 100%;
        }

        ::v-deep .at-select__selection {
          min-height: 50px;
        }
      }

      &__avatar {
        height: 52px;
        width: 52px;

        svg {
          height: 52px;
          width: 52px;
        }
      }

      &__textarea {
        ::v-deep .at-textarea__original {
          min-height: 115px;
        }
      }

      &__input {
        ::v-deep.at-input__original {
          height: 40px;
        }


      }
    }
  }
}

@media screen and (max-width: 320px) {
  .new-donation-modal {
    &__currency-input-selected {
      grid-template-columns: min-content 1fr;
      gap: 10px;
    }
  }

}

</style>
