<template>
    <div v-if="fetching">
        The Preloader
    </div>
  <MainPage v-else />
</template>

<script>
import {mapState} from "vuex";

import MainPage from "./main";

export default {
    name: "precheckout",
    components: {
        MainPage,
    },
    computed: {
        ...mapState(['fetching']),
    },
};
</script>