import Vue from 'vue';
import AtComponents from "at-ui";

import Page from '/public/pages/precheckout/precheckout.vue';

import {checkoutStore} from "/public/pages/precheckout/store";
import {i18n} from '/public/i18n';

Vue.use(AtComponents);

new Vue({
    el: "#app",
    store: checkoutStore,
    render: h => h(Page),
    i18n,
});
