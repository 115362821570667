import Vue from "vue";
import Vuex, {Store} from "vuex";

import {state} from './state';
import {mutations} from './mutations';
import {actions} from './actions';

Vue.use(Vuex);

export const checkoutStore = new Store({
    state,
    mutations,
    actions,
});

checkoutStore.dispatch('fetchPreCheckout', null);
