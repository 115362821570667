import {getCookie} from "@/common/getCookieLang";

export const state = {
    fetching: false,
    precheckout: {
        id: '',
        currencies: [],
        info: {
            avatarLink: '',
            description: '',
            theme: '',
            title: '',
        },
        token: '',
        trace: '',
        url: "",
        utm: '',
        alias: '',
    },
    lang: getCookie('lang') || 'en',
};
