<template>
  <AtSelect
      class="language"
      :value="selectedLang"
      @on-change="selectLang"
  >
    <AtOption
        v-for="(lang, key) in allLangs"
        :key="key"
        :value="lang"

    />
  </AtSelect>

</template>

<script>

import {mapState, mapActions} from 'vuex';
import AtSelect from 'at-ui/src/components/select';
import AtOption from 'at-ui/src/components/option';
import {getCookie} from "../../../../../common/getCookieLang";


export default {
    name: "HeaderLanguage",
    components: {
        AtOption,
        AtSelect,
    },
    data() {
        return {
            langs: [
                'ru',
                'en',
            ],
        };
    },
    computed: {
        ...mapState([
            'lang',
        ]),
        allLangs() {
            return this.langs;
        },
        selectedLang: {
            get() {
                return getCookie('lang') || this.lang;
            },
            async set(value) {
                await this.selectLang(value);
            },
        },
    },
    methods: {
        ...mapActions(
            ['changeLanguage'],
        ),
        selectLang(value) {
            this.changeLanguage(value);
            // location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>

.language {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::v-deep .at-select {
    background: #FFFFFF;
    box-sizing: border-box;
  }

  ::v-deep .at-select__arrow {
    font-size: 24px;
    color: #8F949A;
    margin-top: 0;
    top: calc(50% - 12px);
  }

  &::v-deep .at-select__selection {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    min-height: 46px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }

  &::v-deep .at-select__dropdown .at-select__option {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
    text-transform: capitalize;
  }

  ::v-deep .at-select__selection {
    font-family: var(--font-family);
    font-size: var(--font-size-text-main);
    line-height: var(--line-height);
    color: var(--black-200);
    text-transform: capitalize;
  }

  ::v-deep .at-select__dropdown {
    left: 0;
    top: 46px;
  }
}
</style>
