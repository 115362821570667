import axios from "axios";

export const fetchPreCheckout = async ({state, commit}) => {
    if (state.fetching) {
        return;
    }

    commit('setFetching', true);

    let pcUrl = window.location.pathname.match(/\/([a-zA-Z0-9-_]+)$/);

    if(!(pcUrl && pcUrl.length === 2 && typeof pcUrl[1] === 'string')) {
        commit('setFetching', false);

        return;
    }

    pcUrl = pcUrl[1];

    try {
        const orderResponse = await axios.get('/public-api/pre-checkout/' + pcUrl + (window.location.search));

        const {code, result} = orderResponse.data;

        if (code === 0) {
            window.document.title = orderResponse.data.title;

            commit('setOrder', result);
        } else {
            switch (code) {
                case 3000:
                    // todo: обработать как-то по фронтовски
                    alert('Некорректная ссылка');
                    break;
                case 4000:
                    // todo: обработать как-то по фронтовски
                    alert('нет такой страници');
                    break;
                default:
                    // todo: обработать как-то по фронтовски
                    alert('Internal error, code: ' + code);
                    break;
            }
        }
    } catch (e) {
        console.error('Fail fetch order data', e);
    } finally {
        commit('setFetching', false);
    }
};
